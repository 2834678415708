a:link, a:visited, a:active {
  text-decoration:none;
}
.page-footer{
  background-color: #1155bb;
  color: #fff;
}
.footer-copyright a{
  color: #fff;
  font-weight: 700;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.footer-copyright a:hover{
  opacity: 0.7;
}
.links ul li a{
  color: #fff;
  font-weight: 700;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}
.links ul li a:hover{
  opacity: 0.7;
}
.logo-ceia{
  height: 200px;
}
.ceia-info{
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

}

:root {
  --primary-color: #1155bb;
}

.btn-outline-primary {
  border-radius: 0;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.btn-outline-primary:hover,  .btn-outline-primary:focus, .btn-outline-primary:active{
  background-color: white !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
