.color-menu{
  background-color: #1155bb;
}
.btn-lng{
  font-size: 10px;
  border-radius: 0;
}

:root {
  --primary-color: #1155bb;
}

.btn-outline-primary {
  border-radius: 0;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
}

.btn-outline-primary:hover,  .btn-outline-primary:focus, .btn-outline-primary:active{
  background-color: white !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
