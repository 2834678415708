.logo-footer{
    background-color: #575757;
    color: #fff;
}
.logo{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: transform 0.3s ease-out;
    height: 200px;
}
.logo:hover{
    transform: scale(1.1);
    transition: transform 0.3s ease-in;
    
}
.logo-corfo{
    height: 100px;
}